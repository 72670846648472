import { render, staticRenderFns } from "./HistoricalComparison.vue?vue&type=template&id=514da19e&scoped=true&"
import script from "./HistoricalComparison.vue?vue&type=script&lang=js&"
export * from "./HistoricalComparison.vue?vue&type=script&lang=js&"
import style0 from "./HistoricalComparison.vue?vue&type=style&index=0&id=514da19e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514da19e",
  null
  
)

export default component.exports