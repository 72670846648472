<template>
  <span class="historical-change" :style="{ color: colorBasedOnResult(difference) }">{{differencePrefix}} {{pluralizedUnit}}
    <button v-tooltip="{content: tooltipText, html: true}"><i class="fa fa-info-circle" /></button></span>
</template>

<style lang="scss" scoped>
  .historical-change {
    display: inline;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
  }

  button {
    padding: 0;
    -webkit-appearance: none;
    border: none;
    user-select: none;
  }
</style>

<script>
    export default {
        props: {
            difference: {
                required: true
            },
            unit: {
                type: String,
                required: true
            },
            negativeIsPositive: {
                type: Boolean,
                default: false
            },
            tooltipText: {
                type: String,
                required: true
            }
        },

        computed: {
            pluralizedUnit() {
                return $tc(`units.${this.unit}`, this.difference)
            },

            differencePrefix() {
                const difference = this.difference.toFixed(1).toString()

                return this.difference <= 0 ? difference : '+' + difference
            }
        },

        methods: {
            colorBasedOnResult(value) {
                value = !this.negativeIsPositive ? value : value * -1

                return value === 0 ?
                    '#eee' :
                    value > 0 ?
                        $org('colors.standard.statuses.positive.focus') :
                        $org('colors.standard.statuses.negative.focus')
            }
        }
    }
</script>
