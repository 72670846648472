<template>
    <card>
        <div class="info-list">
            <div class="item">
                <div class="icon">
                    <span><fa icon="mobile-alt"/></span>
                </div>

                <div class="info">
                <span class="headline">
                    {{$t('visitors.facts.mobileVisits.headline')}}
                </span>

                    <div class="desc text-muted" v-if="percentageMobileOrTablet !== null">
                        {{$t('visitors.facts.mobileVisits.description', {percentage:
                        percentageMobileOrTablet.toFixed()})}}
                    </div>

                    <div class="desc text-muted mock" v-else>
                        30% loading
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <span><fa icon="bookmark"/></span>
                </div>

                <div class="info">
                <span class="headline">
                    {{$t('visitors.facts.pagesVisited.headline')}}
                </span>

                    <div class="desc text-muted" v-if="averagePagesVisited !== null">
                        {{$t('visitors.facts.pagesVisited.description', {
                        number: averagePagesVisited.toFixed(1),
                        pages: $tc('visitors.facts.pages', parseInt(averagePagesVisited.toFixed()))
                        })}}
                    </div>

                    <div class="desc text-muted mock" v-else>
                        Loading visitors loading visitor
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="icon">
                    <span><fa icon="clock"/></span>
                </div>

                <div class="info">
                <span class="headline">
                    {{$t('visitors.facts.timeSpend.headline')}}
                </span>

                    <div class="desc text-muted" v-if="averageDuration !== null">
                        {{$t('visitors.facts.timeSpend.description', {
                        number: parseInt(averageDuration),
                        seconds: $tc('visitors.facts.seconds', parseInt(averageDuration))
                        })}}
                    </div>

                    <div class="desc text-muted mock" v-else>
                        Loading visitors loading visitors
                    </div>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
  import HistoricalComparison from '@/app/shared/global/template/HistoricalComparison'
  import { mapGetters } from 'vuex'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  export default {
    mixins: [datepickerMixin],
    computed: {
      ...mapGetters('website', {
        averageDuration: 'getAverageDuration',
        averagePagesVisited: 'getAveragePagesVisited',
        platforms: 'getPlatforms'
      }),

      percentageMobileOrTablet () {
        if (!this.platforms) return null
        return this.platforms
      },

      hasLoaded () {
        return  this.percentageMobileOrTablet !== null &&
          this.averageDuration !== null &&
          this.averagePagesVisited !== null
      }
    },

    components: {
      HistoricalComparison
    }
  }
</script>
